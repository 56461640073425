// 基于element-ui的 全屏加载动画
import { Loading } from "element-ui";
let loading;
let needLoadingRequestCount = 0;

function startLoading () {
  loading = Loading.service({
    text: "加载中……",
    background: "rgba(255, 255, 255, 0.9)",
  });
}

function endLoading () { 
  loading.close();
}
// 打开加载动画
export function showFullScreenLoading () {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
}
// 关闭加载动画
export function tryHideFullScreenLoading () {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}
