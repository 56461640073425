import axios from "axios";
// import QS from "qs";
import router from "../router";
import { tryHideFullScreenLoading } from "@/utils/screenLoading";
import { Notification } from "element-ui";
let localInfo = "";
let localId = "";
let methodType = "";
let domain = {
  // 生产
  Base_M1_URL: "",
  Base_M2_URL: "",
  Base_M3_URL: "",
  Base_M4_URL: "",
};
// 环境
if (process.env.NODE_ENV === "development") {
  domain = {
    Base_M1_URL: "",
    Base_M2_URL: "",
    Base_M3_URL: "",
    Base_M4_URL: "",
  };
} else if (process.env.NODE_ENV === "debug") {
  domain = {
    Base_M1_URL: "https://data.darsing.net/project/digital2",
    Base_M2_URL: "https://public-api.solscan.io",
    Base_M3_URL: "https://api.solscan.io",
    Base_M4_URL: "https://data.darsing.net/project/common",
  };
} else if (process.env.NODE_ENV === "production") {
  domain = {
    Base_M1_URL: "https://data.darsing.net/project/digital2",
    Base_M2_URL: "https://public-api.solscan.io",
    Base_M3_URL: "https://api.solscan.io",
    Base_M4_URL: "https://data.darsing.net/project/common",
  };
}
axios.defaults.timeout = 18000;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers.delete["Content-Type"] = "application/json";
axios.interceptors.request.use(
  function (config) {
    methodType = config.method;
    localInfo = window.localStorage.getItem("userInfoObj") || "";

    if (localInfo) {
      localId = JSON.parse(localInfo).id;
      if (localId == 71 && methodType == "post") return;
    }

    config.data = JSON.stringify(config.data);
    config.headers.Authorization =
      localStorage.getItem("darsingToken") || undefined;
    if (
      config.url == "https://public-api.solscan.io/chaininfo" ||
      config.url == "https://api.solscan.io/v2/amm/chart"
    ) {
      config.headers.token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE2ODM4NjU2MjUyMzUsImVtYWlsIjoieW15bnlnQDE2My5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJpYXQiOjE2ODM4NjU2MjV9.ct_Uc8guoGWjSzjmzyY6CN7iFBzBDTpq_b_oXT6cXO0";
    }
    // 添加加载动画
    // showFullScreenLoading();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    tryHideFullScreenLoading();
    if (response.status === 404) {
      router.push({
        path: "/exception/404",
      });
    } else if (response.status === 403) {
      router.push({
        name: "403",
      });
    } else if (response.status === 500) {
      router.push({
        name: "500",
      });
    } else if (
      response.data.code === 104 ||
      response.data.code === 302 ||
      response.data.code === 401
    ) {
      response.meta.msg = "您的登录信息已过期,请重新登录";
      localStorage.removeItem("web_menu");
      localStorage.removeItem("userInfoObj");
      localStorage.removeItem("farmInfo");
      localStorage.removeItem("darsingToken");
      localStorage.removeItem("farmId");
      sessionStorage.clear();
      router.push({
        name: "login",
      });
    } else if (
      response.data.code != 0 &&
      response.data.code != 200 &&
      response.data.msg
    ) {
      Notification({
        title: "提示",
        message: response.data.msg,
      });
    }
    return response;
  },
  function (error) {
    tryHideFullScreenLoading();
    if (localId == 71 && methodType == "post") {
      Notification({
        title: "提示",
        message: "暂无权限",
      });
      localId = "";
      methodType = "";
    } else {
      Notification({
        title: "提示",
        message: "请求超时，请重新尝试~",
      });
    }
    return Promise.reject(error);
  }
);
export function get(url, params, type = domain.Base_M1_URL) {
  return new Promise((resolve, reject) => {
    axios
      .get(type + url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
export function post(url, params, type = domain.Base_M1_URL) {
  return new Promise((resolve, reject) => {
    axios
      .post(type + url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
export function del(url, params, type = domain.Base_M1_URL) {
  return new Promise((resolve, reject) => {
    axios
      .delete(type + url, { data: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
